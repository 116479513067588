import { React, useEffect, useState } from 'react';
import { API_URL } from "../../shared/constant";
import { Oval } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import axios from 'axios';
import moment from 'moment';

const Outstanding = () => {

    const [isLoading, setLoading] = useState(false);
    const [payType, setPayType] = useState('All');
    const [accountData, setAccountData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [outstandingData, setOutstandingData] = useState({
        total_paid: 0,
        total_received: 0,
        final_amount: 0,
    });

    useEffect(() => {
        getAccount('All', null, null);
    }, []);

    const clearDate = () => {
        setStartDate(null);
        setEndDate(null);
        getAccount(payType, null, null)
    }

    const updateDate = (party_type, fromDate, toDate) => {
        if (fromDate && toDate) {
            getAccount(party_type, fromDate, toDate)
        }
    }

    const getAccount = (party_type, fromDate, toDate) => {
        setPayType(party_type)
        setLoading(true);
        let params = {
            party_type: party_type,
            from_date: (fromDate) ? moment(fromDate).format("YYYY-MM-DD"): null,
            to_date: (toDate) ? moment(toDate).format("YYYY-MM-DD"): null,
        }
        axios.post(API_URL.OUTSTANDING, params).then(res => {
            setAccountData(res.data.data);
            setOutstandingData(res.data.outstanding);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    return (
        <div>
            <div className="page-header">
                <div className="content-page-header">
                    <h5>Outstanding</h5>
                </div>
            </div>
            <div className='card'>
                <div className='card-body p-0'>
                    <div className='row py-3'>
                    <div className='col-md-3 col-sm-12'>
                        <div className="text-center">
                            <div className="form-check form-check-inline">
                                <input name="group1" type="radio" value={'All'} checked={payType === "All"} onChange={(e) => getAccount(e.target.value, startDate, endDate)} id="inline-radio-1" className="form-check-input" />
                                <label title="All" htmlFor="inline-radio-1" className="form-check-label">All</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input name="group1" type="radio" value={'Seller'} checked={payType === "Seller"} onChange={(e) => getAccount(e.target.value, startDate, endDate)} id="inline-radio-2" className="form-check-input" />
                                <label title="Sales" htmlFor="inline-radio-2" className="form-check-label">Sales</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input name="group1" type="radio" value={'Buyer'} checked={payType === "Buyer"} onChange={(e) => getAccount(e.target.value, startDate, endDate)} id="inline-radio-3" className="form-check-input" />
                                <label title="Purchase" htmlFor="inline-radio-3" className="form-check-label">Purchase</label>
                            </div>
                        </div>
                    </div>
                        <div className='col-md-3 col-sm-12'>
                            <DatePicker showIcon placeholderText='From Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={startDate} onChange={(date) => [setStartDate(date), updateDate(payType, date, endDate)]} />
                        </div>
                        <div className='col-md-3 col-sm-12'>
                            <DatePicker showIcon placeholderText='To Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={endDate} onChange={(date) => [setEndDate(date), updateDate(payType, startDate, date)]} />
                        </div>
                        <div className='col-md-3 col-sm-12'>
                            { (startDate && endDate) && <button type='button' className='btn btn-dark' onClick={clearDate}>Clear Date</button> }
                        </div>
                    </div>
                    {
                        !isLoading && <div className="table-responsive">
                            <table className='table table-bordered m-0'>
                                <thead className='thead'>
                                    <tr>
                                        <td>Party</td>
                                        <td>Paid / Sales</td>
                                        <td>Received / Purchase</td>
                                        <td>Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { accountData.map((acc, k) => {
                                        return <tr key={k}>
                                                <td>{acc.party_name}</td>
                                                <td>{acc.paid.toFixed(2)}</td>
                                                <td>{acc.received.toFixed(2)}</td>
                                                <td className={(acc.total < 0) ? 'text-success': 'text-danger'}>{ (Math.abs(acc.total)).toFixed(2) } { (acc.total > 0) ? 'Dr': 'Cr' }</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                                <thead className='thead'>
                                    <tr>
                                        <th>Total</th>
                                        <th>{outstandingData.total_paid.toFixed(2)}</th>
                                        <th>{outstandingData.total_received.toFixed(2)}</th>
                                        <td className={(outstandingData.final_amount < 0) ? 'text-success': 'text-danger'}>{ (Math.abs(outstandingData.final_amount)).toFixed(2) } { (outstandingData.final_amount > 0) ? 'Dr': 'Cr' }</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    }
                </div>
            </div>
            {
                isLoading && <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            }
        </div>
    )
}
export default Outstanding;