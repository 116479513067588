import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from 'moment';
import Select from "react-select";

const Invoices = () => {
  const [partyData, setPartyData] = useState([]);
  const [users, setUsers] = useState([]);
  const [partyId, setPartyId] = useState('');
  const [loading, setLoading] = useState(true);

  const getSales = (pid) => {
    axios.post(API_URL.INVOICE_LIST, { party_id: pid }).then(
      (res) => {
        if (res.data.status === 1) {
          res.data.data.data.map(item => item.date = moment(item.date).format('DD/MM/YYYY'));
          setPartyData(res.data.data.data);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    );
  };

  const getParty = () => {
    axios.post(API_URL.PARTY_LIST, { is_seller: [1] }).then(
      (res) => {
        if (res.data.status === 1) {
          let pt = res.data.data.map((raw) => {
            return {
                address_one: (raw.address_one) ? raw.address_one: "",
                address_two: (raw.address_two) ? raw.address_two: "",
                pincode: (raw.pincode) ? raw.pincode: "",
                city: (raw.city) ? raw.city: "",
                gst_no: (raw.gst_no) ? raw.gst_no: "",
                pan_no: (raw.pan_no) ? raw.pan_no: "",
                state_name: (raw.state_name) ? raw.state_name: "",
                state_id: raw.state_id,
                name: raw.name,
                label: raw.name,
                value: raw.id,
            };
        });
          setUsers(pt);
        }
      });
  };

  const deleteParty = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Invoice No - " + row.invoice_no,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(API_URL.INVOICE_DELETE, { id: row.id }).then(
          (res) => {
            if (res.data.status === 1) {
              let newData = partyData.filter((r) => r.id != row.id);
              setPartyData(newData);
            }
            toast.success("Delete Successfully", {
              autoClose: 3000,
              position: "top-center",
            });
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            toast.error("Something went wrong..", {
              autoClose: 3000,
              position: "top-center",
            });
          }
        );
      }
    });
  };

  const styles = {
    menu: (baseStyles, state) => ({
        ...baseStyles,
        marginTop: 2,
        zIndex: 999,
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      color: "#000",
      zIndex: 999,
    })
  };

  useEffect(() => {
    getSales(null);
    getParty();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Invoices ({partyData.length})</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/invoice/add"} className="btn btn-primary">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
                  Add Invoice
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-table">
            <div className="mb-3" style={{maxWidth: 350}}>
              <Select
                options={users}
                value={users.find((r) => r.value == partyId)}
                styles={styles}
                onChange={(e) => {
                  getSales(e.value)
                  setPartyId(e.value)
                }}
            />
            </div>
            <div className="card-body">
              {loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                <div className="table-responsive">
                  <table className="table table-center table-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>Invoice Date</th>
                        <th className="ps-3">Invoice No</th>
                        <th className="ps-3">Name</th>
                        <th className="ps-3">Amount</th>
                        <th className="ps-3">Qty</th>
                        <th className="ps-3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partyData.map((row, key) => {
                        return (
                          <tr key={key}>
                            <td>{row.date}</td>
                            <td>{row.invoice_no}</td>
                            <td>
                              <h2 className="table-avatar">{row.party.name}</h2>
                            </td>
                            <td>{row.total_amount}</td>
                            <td>{row.total_qty}</td>
                            <td>
                              <Link
                                to={"/admin/invoice/view/" + row.id}
                                className="btn btn-sm btn-success me-2 text-white"
                              >
                                <i className="fa fa-print me-1"></i> Dispatch
                              </Link>
                              <Link
                                to={"/admin/invoice/edit/" + row.id}
                                className="btn btn-sm btn-secondary me-2"
                              >
                                <i className="fa fa-edit me-1"></i> Edit
                              </Link>
                              <button
                                onClick={() => deleteParty(row)}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <i className="fa fa-trash me-1"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;
