import './App.css';
import Router from "./router/Router";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <Router />
  );
}

export default App;
