import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import Select from "react-select";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import html2pdf from 'html2pdf.js';
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
import moment from "moment";

const Prices = () => {

    const [partyData, setPartyData] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [partyId, setPartyId] = useState('');
    const [selectedParty, setSelectedParty] = useState('');
    const [myDate, setMyDate] = useState('');
    const [isGst, setIsGst] = useState(true);
    const userName = sessionStorage.getItem('user_name');

    const styles = {
        menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: 2,
            zIndex: 999,
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            color: "#000",
            zIndex: 999,
        })
    };

    const getParty = () => {
        setLoading(true);
        axios.post(API_URL.PARTY_LIST, { is_seller: [1] }).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                let pt = res.data.data.map((raw) => {
                    return {
                        address_one: (raw.address_one) ? raw.address_one : "",
                        address_two: (raw.address_two) ? raw.address_two : "",
                        pincode: (raw.pincode) ? raw.pincode : "",
                        city: (raw.city) ? raw.city : "",
                        gst_no: (raw.gst_no) ? raw.gst_no : "",
                        pan_no: (raw.pan_no) ? raw.pan_no : "",
                        state_name: (raw.state_name) ? raw.state_name : "",
                        state_id: raw.state_id,
                        name: raw.name,
                        label: raw.name,
                        value: raw.id,
                    };
                });
                setPartyData(pt);
            }
            setLoading(false);
        });
    }

    const getPrice = (pid) => {
        setLoading(true);
        axios.post(API_URL.PARTY_PRICE_LIST, { party_id: pid }).then((res) => {
            if (res.data && res.data.data) {
                setItems(res.data.data);
                if (res.data.data.length) {
                    const dt = moment(new Date(res.data.data[0].created_at)).format('DD/MM/YYYY')
                    setMyDate(dt);
                } else {
                    const dt = moment().format('DD/MM/YYYY')
                    setMyDate(dt);
                }
                
            }
            setLoading(false);
        })
    }

    const setParty = (pid) => {
        const sParty = partyData.find(row => row.value === pid);
        setSelectedParty(sParty);
    }
    

    const saveData = () => {
        setIsLoading(true);
        axios.post(API_URL.PARTY_PRICE_SAVE, { party_id: partyId, items: items }).then((res) => {
            if (res.data && res.data.data) {
                toast.success("Saved Successfully")
            }
            setIsLoading(false);
        })
    }

    const addItems = () => {
        let copyItems = [...items];
        copyItems.push({
            product: "",
            size: "",
            unit: "",
            weight: "",
            rate: ""
        })
        setItems(copyItems);
    }

    const setItemsData = (name, value, k) => {
        let copyItems = [...items];
        copyItems[k][name] = value;
        setItems(copyItems)
    }
    
    const removeRow = (k) => {
        let copyItems = [...items];
        copyItems.splice(k, 1);
        setItems(copyItems)
    }

    const downloadPdf = () => {
        const element = document.getElementById('element-to-print');
        setTimeout(() => {
            var opt = {
                margin: 0.1,
                filename: selectedParty.name + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4' }
            };
            html2pdf().set(opt).from(element).save().then(res => {
                setLoading(false);
            });
        }, 200);
    };

    useEffect(() => {
        getParty();
    }, []);

    return (
        <>
            <div className="page-header">
                <div className="content-page-header">
                    <h5> Price Lists</h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <Select
                                options={partyData}
                                value={partyData.find((r) => r.value == partyId)}
                                styles={styles}
                                onChange={(e) => {
                                    setParty(e.value);
                                    getPrice(e.value);
                                    setPartyId(e.value)
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                                <button
                                type="button"
                                onClick={addItems}
                                className="btn btn-primary w-100">
                                Add Product
                            </button>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <label>
                                <input
                                    value={isGst}
                                    checked={isGst}
                                    type={'checkbox'}
                                    id='defaultCheck'
                                    onChange={(e) => setIsGst(e.target.checked)}
                                /> GST Included
                                </label>
                            </div>
                        </div>
                    </div>
                    {loading ? 
                        <Oval
                        height={60}
                        width={60}
                        color="#7539ff"
                        wrapperStyle={{ justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#7539ff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                        />
                    : <>
                    { (items.length > 0 && partyId) ?
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Product</label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Size</label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Unit</label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Weight</label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Rate</label>
                            </div>
                        </div>
                        <div className="col-md-1">
                            
                        </div>
                    </div>: <div className="text-center">No Price Found</div> }
                    {
                    items.map ((itm, k) => {
                        return <div key={k} className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={itm.product}
                                        onChange={(e) => setItemsData("product", e.target.value, k)}
                                        className="form-control"
                                        placeholder="Product"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={itm.size}
                                        onChange={(e) => setItemsData("size", e.target.value, k)}
                                        className="form-control"
                                        placeholder="Size"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={itm.unit}
                                        onChange={(e) => setItemsData("unit", e.target.value, k)}
                                        className="form-control"
                                        placeholder="Unit"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={itm.weight}
                                        onChange={(e) => setItemsData("weight", e.target.value, k)}
                                        className="form-control"
                                        placeholder="Weight"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={itm.rate}
                                        onChange={(e) => setItemsData("rate", e.target.value, k)}
                                        className="form-control"
                                        placeholder="Rate"
                                    />
                                </div>
                            </div>
                            <div className="col-md-1">
                                <button type="button" onClick={() => removeRow(k) } className="btn btn-sm btn-danger">x</button>
                            </div>
                        </div>
                        })
                    }
                    { (items.length > 0 && partyId) ?
                    <div className="row">
                        <div className="col-md-11 text-end">
                            <button disabled={isLoading} type="button" onClick={saveData} className="btn btn-primary me-2">
                            { isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> : null}
                                Save
                            </button>
                            <button type="button" onClick={() => getPrice(partyId)} className="btn btn-dark me-2">
                                Refresh
                            </button>
                            <button type="button" onClick={downloadPdf} className="btn btn-info">
                                Download PDF
                            </button>
                        </div>
                    </div>: null }
                    </>}
                </div>
            </div>
            <div id="element-to-print" className="ds-none">
                <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th colSpan={6} style={{backgroundColor: "#b5b5c3", textAlign: 'center', fontSize: 18}}> {userName} </th>
                            </tr>
                            <tr>
                                <th colSpan={4} style={{backgroundColor: "#b5b5b5"}}> { (selectedParty) ?  selectedParty.name: ""} </th>
                                <th colSpan={1} style={{textAlign: 'center', backgroundColor: "#3aaee8", color: "#ffffff"}}>{myDate}</th>
                                <th colSpan={1} style={{textAlign: 'center', backgroundColor: "#e83a3a", color: "#ffffff"}}> { isGst ? 'GST Included': 'Without GST' }</th>
                            </tr>
                            <tr>
                                <th>Sr.</th>
                                <th>Product</th>
                                <th>Size</th>
                                <th>Unit</th>
                                <th>Weight</th>
                                <th>Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items.map ((itm, k) => {
                                    return <tr key={k}>
                                        <td>{(k + 1)}</td>
                                        <td>{itm.product}</td>
                                        <td>{itm.size}</td>
                                        <td>{itm.unit}</td>
                                        <td>{itm.weight}</td>
                                        <td>{itm.rate}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                </table>
            </div>
        </>
    );
};

export default Prices;
