import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "./constant";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import html2pdf from 'html2pdf.js';
import moment from 'moment';

const PurchasePdf = () => {

    const { id } = useParams();
    const myLogo = sessionStorage.getItem('logo');
    const [amountInWord, setAmountInWord] = useState("");
    const [invoiceType, setInvoiceType] = useState("Original");
    const [extraItems, setExtraItems] = useState(10);
    const [isLoading, setLoading] = useState(true);
    const [loginUser, setLoginUser] = useState({
        "id": null,
        "name": null,
        "email": null,
        "email_verified_at": null,
        "address_one": null,
        "address_two": null,
        "city": null,
        "pincode": null,
        "gst_no": null,
        "pan_no": null,
        "state_id": null,
        "created_at": null,
        "updated_at": null,
        "state": {
            "id": null,
            "code": null,
            "name": null,
        }
    });
    const [invoice, setInvoice] = useState({
        party: {},
        ship: {},
        bank: {},
        items: []
    });

    const getUserInfo = () => {
        axios.post(API_URL.LOGIN_USER, {}).then((res) => {
            setLoginUser(res.data);
        });
    }
    const getInvoice = () => {
        setLoading(true);
        axios.post(API_URL.PURCHASE_GET, { id: id }).then((res) => {
            if (res.data.status === 1) {
                res.data.data.date = moment(res.data.data.date).format('DD/MM/YYYY');
                setInvoice(res.data.data);
                setAmountInWord(inWords(res.data.data.total_amount));
            } else {
                toast.error(res.data.data);
            }
            setLoading(false);
        });
    }

    const printInvoice = () => {
        // window.print();
        setLoading(true);
        var element = document.getElementById('element-to-print');
        setTimeout(() => {
            var opt = {
                margin: 0.1,
                filename: invoice.id + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4' }
            };
            html2pdf().set(opt).from(element).save().then(res => {
                setLoading(false);
            });
        }, 200);
    }

    const a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    const b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    const inWords = (num) => {
        num = parseInt(num);
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != '0' && n[1] != '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != '0' && n[2] != '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != '0' && n[3] != '00') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != '0' && n[4] != '00') ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != '0' && n[5] != '00') ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
        return str;
    }

    useEffect(() => {
        getInvoice();
    }, [id])

    useEffect(() => {
        getUserInfo();
    }, [])

    return (
        <>
        { isLoading ? 
                    <Oval
                      height={60}
                      width={60}
                      color="#7539ff"
                      wrapperStyle={{ justifyContent: "center" }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#7539ff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />:
                  
        <div id="view-invoice" className="view-invoice" style={{ maxWidth: '900px' }}>
            <div className="toolbar hidden-print" style={{marginBottom: '30px'}}>
                <div className="no-print mb-3">
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <Link to={'/admin/purchase'} className="btn btn-secondary rounded-0 mr-2">Back</Link>
                            <button className="btn btn-primary rounded-0 mr-2" onClick={() => printInvoice()}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="download-invoice" id="element-to-print" style={{"border": "1px solid #000000"}}>
                <section className="invoice-cn section-cn" style={{ backgroundColor: "#fff" }}>
                    <table className="table fz-14 print-table">
                        <thead>
                            <tr>
                                <th colSpan={4} className='text-center border-bottom bg-secondary text-white'><b>DISPATCH</b></th>
                            </tr>
                            <tr>
                                <th width={80}>Party</th>
                                <th className="border-end">{invoice.party.name}</th>
                                <th width={100}>Invoice No</th>
                                <th>{invoice.invoice_no}</th>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <th className="border-end">{invoice.party.address_one} {invoice.party.address_two}</th>
                                <th>Date</th>
                                <th>{invoice.date}</th>
                            </tr>
                            <tr>
                                <th>City</th>
                                <th className="border-end">{invoice.party.city}</th>
                                <th>Transport</th>
                                <th>{invoice.transport}</th>
                            </tr>
                            <tr>
                                <th>State</th>
                                <th className="border-end">{invoice.party.state_name}</th>
                                <th>Vehicle No</th>
                                <th>{invoice.vehicle_no}</th>
                            </tr>
                        </thead>
                    </table>
                    <table className="table fz-14 border-top print-table">
                        <thead>
                            <tr>
                                <th className="border-end" style={{width: '50px'}} >Sr.</th>
                                <th className="border-end">Description Of Goods</th>
                                <th className="border-end">Grade</th>
                                <th className="border-end" style={{width: '80px'}}>Qty</th>
                                <th className="border-end" style={{width: '70px'}}>Unit</th>
                                <th className="border-end" style={{width: '120px'}}>Rate</th>
                                <th style={{width: '120px'}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            { invoice.items.map((itm, k) => {
                                return <tr key={k}>
                                    <td className="border-top border-end">{(k + 1)}</td>
                                    <td className="border-top border-end">{itm.subproduct.name}</td>
                                    <td className="border-top border-end">{itm.grade.name}</td>
                                    <td className="border-top border-end">{itm.qty}</td>
                                    <td className="border-top border-end">{itm.subproduct.unit}</td>
                                    <td className="border-top border-end" style={{width: '120px'}}>{itm.rate}</td>
                                    <td className="border-top" style={{width: '120px'}}>{itm.amount}</td>
                                </tr>
                                })
                            }
                            </tbody>
                        </table>
                        <table className="table fz-14 border-top print-table">
                            <tbody>
                            <tr>
                                <td className="border-top" colSpan={3}></td>
                                <td className="border-top border-start border-end" style={{width: '80px'}}><b>{invoice.total_qty}</b></td>
                                <td className="border-top border-end" style={{width: '70px'}}></td>
                                <td className="border-top text-end" style={{width: '120px'}}>Sub Total</td>
                                <td className="border-top border-start" style={{width: '120px'}}><b>{invoice.sub_total}</b></td>
                            </tr>
                            <tr>
                                <td className="border-top">Remark: </td>
                                <td className="border-top" colSpan={4}>{invoice.remark}</td>
                                <td className="border-top text-end border-start" style={{width: '120px'}}>GST</td>
                                <td className="border-top border-start" style={{width: '120px'}}><b>{invoice.tax_amount}</b></td>
                            </tr>
                            <tr>
                                <td className="border-top"></td>
                                <td className="border-top"></td>
                                <td className="border-top"></td>
                                <td className="border-top"></td>
                                <td className="border-top"></td>
                                <td className="border-top text-end border-start" style={{width: '120px'}}>Round Off</td>
                                <td className="border-top border-start" style={{width: '120px'}}><b>{invoice.round_off}</b></td>
                            </tr>
                            <tr>
                                <td className="border-top bg-success text-white"><b>Bill Amount</b></td>
                                <td className="border-top bg-success text-white"><b>{invoice.bill_amount}</b></td>
                                <td className="border-top bg-danger text-white"><b>Cash Amount</b></td>
                                <td className="border-top bg-danger text-white"><b>{invoice.cash_amount}</b></td>
                                <td className="border-top bg-secondary text-white text-end" style={{width: '120px'}} colSpan={2}><b>Total Amount</b></td>
                                <td className="border-top bg-secondary text-white" style={{width: '120px'}}><b>{invoice.total_amount}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div> }
        </>
    )
}

export default PurchasePdf;