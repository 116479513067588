import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const userName = sessionStorage.getItem('user_name');
    const logout = () => {
        sessionStorage.clear();
        navigate("/", { replace: true });
    }
    return (
        <div className="header header-one hidden-print">
            <ul className="nav nav-tabs user-menu float-start">
                <li className='nav-item'>
                    <Link to="/admin/party">Party</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/prices">Prices</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/product">Products</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/invoice">Sales</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/purchase">Purchase</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/payments">Payments</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/ledgers">Ledger</Link>
                </li>
                <li className='nav-item'>
                    <Link to="/admin/outstanding">Outstanding</Link>
                </li>
            </ul>
            <ul className="nav nav-tabs user-menu">
                <li className="nav-item dropdown">
                    <button className='btn btn-primary' onClick={logout}>Log Out</button>
                </li>
            </ul>
        </div>
    )
}

export default Header;