import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Oval } from "react-loader-spinner";

const AddInvoice = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const selectInputRef = useRef();
    const selectGradeInputRef = useRef();
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [partyData, setPartyData] = useState([]);
    const [products, setProducts] = useState([]);
    const [grades, setGrades] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [productData, setProductData] = useState({
        name: "",
        product_id: "",
        grade_name: "",
        grade_id: "",
        qty: "",
        rate: "",
        amount: "",
    });
    const [formData, setFormData] = useState({
        "party_id": "",
        "invoice_no": "",
        "date": "",
        "sub_total": "0",
        "tax_amount": "0",
        "cash_amount": "0",
        "bill_amount": "0",
        "total_amount": "0",
        "round_off": "0",
        "transport": "",
        "vehicle_no": "",
        "remark": "",
    });
    
    const styles = {
        menu: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: 2,
            zIndex: 999,
        }),
    };

    const getParty = () => {
        axios.post(API_URL.PARTY_LIST, { is_seller: [1] }).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                let pt = res.data.data.map((raw) => {
                    return {
                        address_one: (raw.address_one) ? raw.address_one: "",
                        address_two: (raw.address_two) ? raw.address_two: "",
                        pincode: (raw.pincode) ? raw.pincode: "",
                        city: (raw.city) ? raw.city: "",
                        gst_no: (raw.gst_no) ? raw.gst_no: "",
                        pan_no: (raw.pan_no) ? raw.pan_no: "",
                        state_name: (raw.state_name) ? raw.state_name: "",
                        state_id: raw.state_id,
                        name: raw.name,
                        label: raw.name,
                        value: raw.id,
                    };
                });
                setPartyData(pt);
            }
        });
    }

    const getEdit = () => {
        axios.post(API_URL.INVOICE_GET, {id: id}).then((res) => {
            if (res.data.status == 1) {
                setFormData({
                    "party_id": (res.data.data.party_id) ? res.data.data.party_id : "",
                    "invoice_no": (res.data.data.invoice_no) ? res.data.data.invoice_no : "",
                    "sub_total": res.data.data.sub_total,
                    "bill_amount": res.data.data.bill_amount,
                    "cash_amount": res.data.data.cash_amount,
                    "tax_amount": res.data.data.tax_amount,
                    "total_amount": res.data.data.total_amount,
                    "round_off": (res.data.data.round_off) ? res.data.data.round_off.toFixed(2): "0",
                    "date": (res.data.data.date) ? res.data.data.date : "",
                    "remark": (res.data.data.remark) ? res.data.data.remark : "",
                    "transport": (res.data.data.transport) ? res.data.data.transport : "",
                    "vehicle_no": (res.data.data.vehicle_no) ? res.data.data.vehicle_no : "",
                });
                if (res.data.data.date) {
                    setInvoiceDate(new Date(res.data.data.date));
                }
                let rows = res.data.data.items.map((row) => {
                    return {
                        name: row.subproduct.name,
                        qty: row.qty,
                        rate: row.rate,
                        amount: row.amount,
                        product_id: row.sub_product_id,
                        grade_name: row.grade.name,
                        grade_id: row.grade_id,
                        sub_product_id: row.sub_product_id
                    }
                });
                setItems(rows);
            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
            setLoading(false);
        });
    }
    
    const getProducts = () => {
        axios.post(API_URL.PRODUCT_LIST, {}).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map((raw) => {
                    raw.label = raw.name;
                    raw.value = raw.id;
                });
                setProducts(res.data.data);
            }
            if (!id) {
              setLoading(false);
            }
        });
    }
    
    const getGrades = () => {
        axios.post(API_URL.GRADE_LIST, {}).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map((raw) => {
                    raw.label = raw.name;
                    raw.value = raw.id;
                });
                setGrades(res.data.data);
            }
            if (!id) {
              setLoading(false);
            }
        });
    }

    const setSelectedParty = (party_id) => {
        setFormData({...formData, party_id: party_id });
    }

    const setProductValue = (e) => {
        if (e) {
            setProductData({
                ...productData,
                name: e ? e.label: "",
                product_id: e ? e.value: "",
            });               
        }
    }

    const setGradeValue = (e) => {
        if (e) {
            setProductData({
                ...productData,
                grade_name: e ? e.label: "",
                grade_id: e ? e.value: ""
            });               
        }
    }

    const addRow = () => {
        if (productData.product_id && productData.grade_id && productData.qty && productData.rate && productData.amount) {
            let cloneItems = [...items];
            cloneItems.push(productData);
            setItems(cloneItems);
            setProductData({
                name: "",
                product_id: "",
                grade_name: "",
                grade_id: "",
                qty: "",
                rate: "",
                amount: "",
            });
            selectInputRef.current.clearValue();
            selectGradeInputRef.current.clearValue();
        }
    }
    const updateValue = (name, amt, value, k) => {
        const copyData = [...items]
        if (copyData[k]) {
            copyData[k][name] = value;
            if (name === 'qty') {
                copyData[k][amt] = value * copyData[k]['rate']
            }
            if (name === 'rate') {
                copyData[k][amt] = value * copyData[k]['qty']
            }
            copyData[k][amt] = Math.round(copyData[k][amt] * 100) / 100;

        }
        setItems(copyData);
    }

    const deleteRow = (index) => {
        const copyData = [...items]
        let newData = copyData.filter((e, key) => {
            return key != index;
        });
        setItems(newData)
    }

    const calculations = () => {
        let sub_total = 0;
        let total_qty = 0;
        items.forEach((row) => {
            sub_total += parseFloat(row.amount);
            total_qty += parseFloat(row.qty);
        });
        sub_total = sub_total.toFixed(2);
        let total_amount = parseFloat(sub_total);
        if (formData.tax_amount) {
            total_amount = parseFloat(total_amount) + parseFloat(formData.tax_amount);
        }
        if (formData.round_off) {
            total_amount = parseFloat(total_amount) + parseFloat(formData.round_off);
        }
        let cash_amount = parseFloat(total_amount);
        if (formData.bill_amount) {
            cash_amount = parseFloat(total_amount) - parseFloat(formData.bill_amount);
        }
        cash_amount = cash_amount.toFixed(2);
        total_amount = total_amount.toFixed(2);
        setFormData({...formData,
            sub_total: sub_total,
            total_qty: total_qty,
            cash_amount: cash_amount,
            total_amount: total_amount,
        });
    }

    const isValidForm = () => {
        return (
          formData.party_id &&
          formData.invoice_no &&
          formData.sub_total &&
          formData.total_amount &&
          invoiceDate &&
          items.length
        );
    };
    
    const saveInvoice = () => {
        let payload = {...formData};
        payload.items = [...items];
        payload.date = moment(invoiceDate).format('YYYY-MM-DD');
        const pUrl = id ? API_URL.INVOICE_UPDATE : API_URL.INVOICE_SAVE;
        if (id) {
            payload.id = id;
        }
        setIsLoading(true);
        axios.post(pUrl, payload).then((res) => {
            if (res.data.status === 1) {
                if (id) {
                    toast.success("Updated", {
                        autoClose: 3000,
                        position: "top-center",
                    });
                } else {
                    toast.success("Saved", { autoClose: 3000, position: "top-center" });
                }
                setTimeout(() => {
                    navigate("/admin/invoice", { replace: true });
                }, 100);        

            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
            setIsLoading(false);
        }, (error) => {
          setIsLoading(false);
        });
    } 

    useEffect(() => {
        calculations();
    }, [items, formData.round_off, formData.tax_amount, formData.bill_amount])

    useEffect(() => {
        getParty();
        getProducts();
        getGrades();
    }, [])

    useEffect(() => {
        if (id) {
            getEdit()
        }
    }, [id])
    return (
        <>
            <div className="page-header">
                <div className="content-page-header">
                    <h5>{id ? "Edit" : "Add"} Invoice</h5>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <Link to={"/admin/invoice"} className="btn btn-secondary">
                                    <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            { loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : <>
            <form onSubmit={(event) => event.preventDefault()}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Sales Details</h4>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="name">Party</label>
                                            <Select
                                                options={partyData}
                                                value={partyData.find((r) => r.value == formData.party_id)}
                                                styles={styles}
                                                onChange={(e) => {
                                                    setSelectedParty(e.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="invoiceno">Invoice No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.invoice_no}
                                                onChange={(e) => setFormData({ ...formData, invoice_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="">Invoice Date</label>
                                            <DatePicker
                                                className="form-control"
                                                onChange={setInvoiceDate}
                                                selected={invoiceDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="invoiceno">Transport</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.transport}
                                                onChange={(e) => setFormData({ ...formData, transport: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="invoiceno">Vehicle No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.vehicle_no}
                                                onChange={(e) => setFormData({ ...formData, vehicle_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Product Details</h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Product</label>
                                            <Select
                                                ref={selectInputRef}
                                                options={products}
                                                styles={styles}
                                                onChange={(e) => { setProductValue(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Grade</label>
                                            <Select
                                                ref={selectGradeInputRef}
                                                options={grades}
                                                styles={styles}
                                                onChange={(e) => { setGradeValue(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Qty</label>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        qty: e.target.value,
                                                        amount: (e.target.value * productData.rate) ? e.target.value * productData.rate : 0,
                                                    });
                                                }}
                                                value={productData.qty}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Price</label>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        rate: e.target.value,
                                                        amount: (e.target.value * productData.qty) ? (e.target.value * productData.qty).toFixed(2) : 0,
                                                    });
                                                }}
                                                value={productData.rate}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Amount</label>
                                            <input
                                                type="text"
                                                value={productData.amount}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <div>
                                                <button onClick={() => addRow()} className="btn btn-primary btn-outline"><span className="fa fa-plus f-20"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    {items.length > 0 &&
                        <table className='table table-hover'>
                            <thead className='thead'>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '45%' }}>Product</th>
                                    <th>Grade</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((e, k) => {
                                        return <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td style={{ width: '45%' }}>{e.name}</td>
                                            <td>{e.grade_name}</td>
                                            <td><input type="text" onChange={(event) => { updateValue('qty', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.qty} /></td>
                                            <td><input type="text" onChange={(event) => { updateValue('rate', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.rate} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={e.amount} disabled /></td>
                                            <td style={{ cursor: "pointer" }}>
                                                <button onClick={() => { deleteRow(k) }} className="btn btn-danger btn-outline"><span className="fa fa-close"></span></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Total Amount</h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="sub_total">Sub Total</label>
                                            <input
                                                disabled
                                                type="text"
                                                className="form-control"
                                                value={formData.sub_total}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="tax_amount">Total Tax</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.tax_amount}
                                                onChange={(e) => setFormData({...formData, tax_amount: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="round_off">Round Off</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.round_off}
                                                onChange={(e) => setFormData({...formData, round_off: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="bill_amount">Bill Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.bill_amount}
                                                onChange={(e) => setFormData({...formData, bill_amount: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="cash_amount">Cash Amount</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={formData.cash_amount}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="total_amount">Total Amount</label>
                                            <input
                                                disabled
                                                type="text"
                                                className="form-control"
                                                value={formData.total_amount}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label htmlFor="remark">Remarks</label>
                                            <input type={'text'} maxLength={255} value={formData.remark} placeholder="Remark" className='form-control' onChange={(e) => setFormData({...formData, remark: e.target.value})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group text-end">
                    <button
                        type="button"
                        onClick={saveInvoice}
                        disabled={!isValidForm() || isLoading}
                        className="btn btn-primary" >
                          { isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> : null}
                        Save 
                    </button>
                </div>
            </div> </>
          }
        </>
    )
}

export default AddInvoice;