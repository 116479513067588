const BASE_URL = 'https://api.gnamcera.com/api/';
// const BASE_URL = 'https://api.hitnish.com/api/';

export const API_URL = {

    GRADE_LIST: `${BASE_URL}grade`,
    GRADE_GET: `${BASE_URL}grade/show`,
    GRADE_SAVE: `${BASE_URL}grade/store`,
    GRADE_UPDATE: `${BASE_URL}grade/update`,
    GRADE_DELETE: `${BASE_URL}grade/delete`,

    PARTY_LIST: `${BASE_URL}party`,
    PARTY_PRICE_LIST: `${BASE_URL}party/prices`,
    PARTY_PRICE_SAVE: `${BASE_URL}party/prices/save`,
    PARTY_GET: `${BASE_URL}party/show`,
    PARTY_SAVE: `${BASE_URL}party/store`,
    PARTY_UPDATE: `${BASE_URL}party/update`,
    PARTY_DELETE: `${BASE_URL}party/delete`,

    PRODUCT_LIST: `${BASE_URL}sub-product`,
    PRODUCT_GET: `${BASE_URL}sub-product/show`,
    PRODUCT_SAVE: `${BASE_URL}sub-product/store`,
    PRODUCT_UPDATE: `${BASE_URL}sub-product/update`,
    PRODUCT_DELETE: `${BASE_URL}sub-product/delete`,
    
    SETTING_SAVE: `${BASE_URL}setting/store`,
    SETTING_GET: `${BASE_URL}setting/show`,
    SETTING_PASSWORD: `${BASE_URL}setting/password`,
    LOGIN_URL: `${BASE_URL}login`,
    STATES_LIST: `${BASE_URL}states`,
    LOGIN_USER: `${BASE_URL}me`,

    INVOICE_LIST: `${BASE_URL}my-sales`,
    INVOICE_GET: `${BASE_URL}my-sales/show`,
    INVOICE_SAVE: `${BASE_URL}my-sales/store`,
    INVOICE_UPDATE: `${BASE_URL}my-sales/update`,
    INVOICE_DELETE: `${BASE_URL}my-sales/delete`,

    PURCHASE_LIST: `${BASE_URL}my-purchase`,
    PURCHASE_GET: `${BASE_URL}my-purchase/show`,
    PURCHASE_SAVE: `${BASE_URL}my-purchase/store`,
    PURCHASE_UPDATE: `${BASE_URL}my-purchase/update`,
    PURCHASE_DELETE: `${BASE_URL}my-purchase/delete`,
    
    PAYMENT_LIST: `${BASE_URL}payments`,
    PAYMENT_GET: `${BASE_URL}payments/show`,
    PAYMENT_SAVE: `${BASE_URL}payments/store`,
    PAYMENT_UPDATE: `${BASE_URL}payments/update`,
    PAYMENT_DELETE: `${BASE_URL}payments/delete`,

    LEDGER_LIST: `${BASE_URL}ledgers`,
    OUTSTANDING: `${BASE_URL}ledgers/outstanding`,
}

