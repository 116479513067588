import React from 'react';
import Header from "../pages/include/Header";
import Sidebar from "../pages/include/Sidebar";
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

// For GET requests
axios.interceptors.request.use(
    (req) => {
        // Add configurations here
        req.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        return req;
    },
    (err) => {
        if (err.response.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

// For POST requests
axios.interceptors.response.use(
    (res) => {
        res.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        if (res.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return res;
    },
    (err) => {
        if (err.response && err.response.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

const AdminLayout = ({ component: Component }) => {

    const isLogin = sessionStorage.getItem('access_token');
    if (!isLogin) {
        window.location.href = '/login';
    }
    
    return (
        <div className="main-wrapper">
            { isLogin && <Header /> }
            {/* { isLogin && <Sidebar /> } */}
            { isLogin && <div className="page-wrapper">
                <div className="content container-fluid">
                    {Component}
                </div>
            </div> }
            <ToastContainer />
        </div>
    )
};

export default AdminLayout;  