
import Dashboard from '../pages/dashboard';
import Party from '../pages/party';
import AddParty from '../pages/party/add';
import Product from '../pages/product';
import Purchase from '../pages/purchase';
import AddPurchase from '../pages/purchase/add';
import AddInvoices from '../pages/invoice/add';
import Invoices from '../pages/invoice';
import Perfoma from '../pages/sales/perfoma';
import AddPerfoma from '../pages/sales/addPerfoma';
import Profile from '../pages/settings/profile';
import Pdf from '../shared/pdf';
import Payment from '../pages/payment';
import Ledger from '../pages/ledger';
import Outstanding from '../pages/ledger/outstanding';
import PurchasePdf from '../shared/viewPdf';
import Prices from '../pages/party/prices';


const AdminRoutes = [
    { path: '', component: <Dashboard /> },
    { path: 'dashboard', component: <Dashboard /> },
    { path: 'party', component: <Party /> },
    { path: 'party/add', component: <AddParty /> },
    { path: 'party/edit/:id', component: <AddParty /> },
    { path: 'prices', component: <Prices /> },
    { path: 'product', component: <Product /> },
    { path: 'payments', component: <Payment /> },
    { path: 'invoice', component: <Invoices /> },
    { path: 'invoice/add', component: <AddInvoices /> },
    { path: 'invoice/edit/:id', component: <AddInvoices /> },
    { path: 'invoice/view/:id', component: <Pdf /> },
    { path: 'purchase', component: <Purchase /> },
    { path: 'purchase/add', component: <AddPurchase /> },
    { path: 'purchase/edit/:id', component: <AddPurchase /> },
    { path: 'purchase/view/:id', component: <PurchasePdf /> },
    { path: 'perfoma', component: <Perfoma /> },
    { path: 'add-perfoma', component: <AddPerfoma /> },
    { path: 'ledgers', component: <Ledger /> },
    { path: 'outstanding', component: <Outstanding /> },
    
    { path: 'profile', component: <Profile /> }
];

export default AdminRoutes;